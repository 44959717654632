<script setup lang="ts" generic="T, Key extends string">
const props = defineProps<{
    modelValue: T;
    options: Record<Key, T>;
    default: Key;
    selectedAutoWidth: boolean;
}>();

defineEmits({
    "update:modelValue": (_value: T) => true,
});

const selected = ref(props.default);
const open = ref(false);

const label = ref<HTMLElement>();
const items = ref<HTMLElement>();

const width = computed(() => {
    if (!label.value || !items.value) return;
    return Math.max(label.value.offsetWidth, items.value.offsetWidth);
});
</script>

<template>
    <div
        v-click-outside="
            () => {
                open = false;
            }
        "
        class="app-select"
        @blur="open = false"
    >
        <div
            ref="label"
            class="selected"
            :style="{
                width,
                minWidth: selectedAutoWidth ? 'auto' : undefined,
            }"
            :class="{ width }"
            @click="open = !open"
        >
            <slot name="label" :label="selected" :open="open">
                <div class="default-label" :class="{ open }">
                    {{ selected }}
                </div>
            </slot>
        </div>
        <div
            ref="items"
            class="items"
            :style="{ width }"
            :class="{ open: open, close: !open }"
        >
            <span
                v-for="(value, name) of options"
                :key="name"
                class="item"
                @click="
                    selected = name as any;
                    open = false;
                    $emit('update:modelValue', value);
                "
            >
                <slot name="item" :item="{ value, name }">
                    {{ name }}
                </slot>
            </span>
        </div>
    </div>
</template>

<style scoped lang="scss">
.app-select {
    position: relative;
}

.selected {
    min-width: 225px;
    cursor: pointer;
    user-select: none;
}

.default-label {
    border: 1px solid #888;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    padding: 0 32px 0 14px;
    line-height: 36px;

    &:after {
        top: 50%;
        right: 13px;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border: solid rgba(41, 79, 115, 0);
        border-top: solid #294f73;
        border-width: 5px 4px;
        transform: translateY(-3px);
        transition: transform 0.2s ease;
    }

    &.open {
        &:after {
            transform: rotateZ(180deg) translateY(7px);
        }
    }
}

.items {
    width: 225px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    background: #fff;
    border: 1px solid #b8b8bf;
    box-sizing: border-box;
    margin-top: 16px;
    overflow: hidden;
    transition: all 0.15s ease-in;

    &.close {
        display: none;
        margin-top: 32px;
    }

    &.open {
        display: block;
        margin-top: 16px;
    }

    .item {
        display: block;
        width: 100%;
        cursor: pointer;
        font-size: 14px;
        line-height: 36px;
        color: rgb(12, 15, 56);
        padding: 0 10px;

        &:hover {
            background-color: hsla(0, 0%, 86.3%, 0.5);
        }
    }
}
</style>
