<script setup>
defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    value: {
        type: String,
        default: undefined,
    },
    name: {
        type: String,
        required: true,
    },
});
const name = toRef(props, "name");

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
} = useField(name, undefined, {
    initialValue: props.value,
    validateOnValueUpdate: false,
});

const isVisible = ref(false);
function togglePassword(e) {
    e.target.classList.toggle("active");
    isVisible.value = !isVisible.value;
}
</script>

<template>
    <div class="password">
        <input
            class="password-input"
            :name="name"
            :value="inputValue"
            @input="handleChange($event, false)"
            v-bind="$attrs"
            :type="isVisible ? 'text' : 'password'"
            :class="{ invalid: errorMessage }"
        />

        <a
            @click="togglePassword"
            href="javascript:void(0);"
            class="password-control"
        ></a>
    </div>
</template>

<style lang="scss">
.password {
    position: relative;
}

.password-control {
    position: absolute;
    top: 8px;
    right: 2px;
    width: 20px;
    height: 20px;
    background: url(@/assets/images/eye.svg) 0 0 no-repeat;
    transition: all 0.3s;

    &.active {
        background: url(@/assets/images/eye-close.svg) 0 0 no-repeat;
    }
}

.password {
    position: relative;
}

.password-control {
    position: absolute;
    top: 8px;
    right: 2px;
    width: 20px;
    height: 20px;
    background: url(@/assets/images/eye.svg) 0 0 no-repeat;
    transition: all 0.3s;

    &.active {
        background: url(@/assets/images/eye-close.svg) 0 0 no-repeat;
    }
}
</style>
